// Create custom containers for ChartJS charts

// Area Chart
.chart-area {
    position: relative;
    height: 10rem;
    width: 100%;

    @include media-breakpoint-up(md) {
        height: 15rem;
    }
}

// Bar Chart
.chart-bar {
    position: relative;
    height: 10rem;
    width: 100%;

    @include media-breakpoint-up(md) {
        height: 15rem;
    }
}

// Pie Chart
.chart-pie {
    position: relative;
    height: 15rem;
    width: 100%;

    @include media-breakpoint-up(md) {
        height: 15rem;
    }
}
