@import './optum/variables';

// 
// Variable overrides
// 

// This file is intentionally blank
// Use this file to override any core SCSS variables

$banner-height: 3.5rem;

$footer-base-height: 3rem;

$sidenav-light-bg: $oc-gray-7;
$sidenav-light-color: $grey2;
$sidenav-light-link-active-color: $oc-orange-4;

