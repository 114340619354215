$fontIconsMap: (
    person: '\e900',
    group: '\e901',
    contacts: '\e902',
    globe: '\e903',
    home: '\e904',
    dashboard: '\e905',
    save: '\e906',
    print: '\e907',
    folder_closed: '\e908',
    folder_open: '\e909',
    settings: '\e90a',
    filter: '\e90b',
    edit: '\e90c',
    calendar: '\e90d',
    trash_delete: '\e90e',
    trash_full: '\e90f',
    share: '\e910',
    lock: '\e911',
    lock_unlock: '\e912',
    key: '\e913',
    email: '\e914',
    email_reply: '\e915',
    email_reply_all: '\e916',
    email_forward: '\e917',
    direct_mail: '\e918',
    chat: '\e919',
    notes: '\e91a',
    attachment: '\e91b',
    pin: '\e91c',
    location: '\e91d',
    link: '\e91e',
    link_broken: '\e91f',
    bookmark: '\e920',
    flag: '\e921',
    award: '\e922',
    star_rating_hollow: '\e923',
    star_rating_filled: '\e924',
    favorite_hollow: '\e925',
    favorite_filled: '\e926',
    info_hollow: '\e927',
    help_hollow: '\e928',
    alert_hollow: '\e929',
    warning_hollow: '\e92a',
    prohibited_slash: '\e92b',
    prohibited_x: '\e92c',
    process: '\e92d',
    checkmark_hollow: '\e92e',
    checkmark: '\e92f',
    asterisk: '\e930',
    clock: '\e931',
    stopwatch: '\e932',
    refresh: '\e933',
    history: '\e934',
    undo: '\e935',
    redo: '\e936',
    arrow_down: '\e937',
    arrow_left: '\e938',
    arrow_right: '\e939',
    arrow_up: '\e93a',
    sort: '\e93b',
    sort_up: '\e93c',
    sort_down: '\e93d',
    caret_left: '\e93e',
    caret_right: '\e93f',
    rewind: '\e940',
    forward: '\e941',
    chevron_left: '\e942',
    chevron_right: '\e943',
    move: '\e944',
    chart_column: '\e945',
    chart_column_stacked_100: '\e946',
    chart_column_stacked: '\e947',
    chart_line: '\e948',
    chart_line_isolated: '\e949',
    chart_pie: '\e94a',
    chart_row: '\e94b',
    chart_row_stacked: '\e94c',
    chart_row_stacked_100: '\e94d',
    document: '\e94e',
    copy: '\e94f',
    copy_cant: '\e950',
    copy_warning: '\e951',
    document_download: '\e952',
    document_forward: '\e953',
    upload: '\e954',
    download: '\e955',
    document_search: '\e956',
    export: '\e957',
    import: '\e958',
    ellipsis: '\e959',
    fitness_device: '\e95a',
    list: '\e95b',
    menu: '\e95c',
    phone: '\e95d',
    cloud: '\e95e',
    wifi: '\e95f',
    video: '\e960',
    brightness: '\e961',
    power: '\e962',
    screen_maximize: '\e963',
    screen_minimize: '\e964',
    expand: '\e965',
    contract: '\e966',
    new_window: '\e967',
    remove: '\e968',
    close: '\e969',
    add: '\e96a',
    subtract: '\e96b',
    view: '\e96c',
    view_hide: '\e96d',
    search: '\e96e',
    zoom_in: '\e96f',
    zoom_out: '\e970',
    ambulance: '\e971',
    medication: '\e972',
    request: '\e973',
    accessible: '\e974',
    tooth: '\e975',
    allergies: '\e976',
    pharmacy: '\e977',
    xray: '\e978',
    immunization: '\e979',
    lab_results: '\e97a',
    blood_pressure: '\e97b',
    vitals: '\e97c',
    hazardous_materials: '\e97d',
    new: '\e97e',
    pdf: '\e97f',
    xls: '\e980',
    medical_symbol: '\e981',
    kidneys: '\e982',
    splitter_vertical: '\e983',
    identification_card: '\e984',
    clinical_overview: '\e985',
    iv: '\e986',
    revenue_cycle: '\e987',
    virtual_care: '\e988',
    checkbox_hollow: '\e989',
    splitter_horizontal: '\e98a',
    checkbox_filled: '\e98b',
    alert_filled: '\e98c',
    checkbox_empty: '\e98d',
    radio_button_filled: '\e98e',
    radio_button_empty: '\e98f',
    laptop: '\e990',
    guidelines: '\e991',
    caret_up_centered: '\e992',
    caret_down_centered: '\e993',
    checkmark_filled: '\e994',
    tablet: '\e995',
    dollar_sign: '\e996',
    dollar_sign_hollow: '\e997',
    dollar_sign_filled: '\e998',
    help_filled: '\e999',
    info_filled: '\e99a',
    warning_filled: '\e99b',
    bookmark_filled: '\e99c',
    arrow_wtail_bold_up: '\e99d',
    arrow_wtail_bold_down: '\e99e',
    arrow_wtail_bold_left: '\e99f',
    arrow_wtail_bold_right: '\e9a0',
    advocacy: '\e9a1',
    alternative_therapies: '\e9a2',
    community: '\e9a3',
    data: '\e9a4',
    diabetes: '\e9a5',
    digestive_health: '\e9a6',
    handshake: '\e9a7',
    fitness: '\e9a8',
    hearing: '\e9a9',
    hospice: '\e9aa',
    long_term_care: '\e9ab',
    baby_bottle: '\e9ac',
    mens_health: '\e9ad',
    orthopedics: '\e9ae',
    sleep: '\e9af',
    smoking_cessation: '\e9b0',
    mental_health: '\e9b1',
    womens_health: '\e9b2',
    groceries: '\e9b3',
    self_care: '\e9b4',
    shopping_cart: '\e9b5',
    briefcase: '\e9b6',
    dock: '\e9b7',
    undock: '\e9b8',
    search_field_clear: '\e9b9',
    coaching: '\e9ba',
    referral: '\e9bb',
    healthcare: '\e9bc',
    emeritus: '\e9bd',
    pause: '\e9be',
    play: '\e9bf',
    stop: '\e9c0',
    sticky_note: '\e9c1',
    children: '\e9c2',
    coder: '\e9c3',
    compass: '\e9c4',
    doctor_patient: '\e9c5',
    emergency_room: '\e9c6',
    expertise: '\e9c7',
    financial_security: '\e9c8',
    health_profile: '\e9c9',
    notification: '\e9ca',
    customer_service: '\e9cb',
    nurse_line: '\e9cc',
    family: '\e9cd',
    provider: '\e9ce',
    exercise: '\e9cf',
    workers_comp: '\e9d0',
    communicate: '\e9d1',
    building: '\e9d2',
    hospital: '\e9d3',
    government: '\e9d4',
    health: '\e9d5',
    nutrition: '\e9d6',
    computer: '\e9d7',
    computer_stats: '\e9d8',
    results: '\e9d9',
    claim: '\e9da',
    eye_exam: '\e9db',
    finances: '\e9dc',
    overdue: '\e9dd',
    mobile: '\e9de',
    go_green: '\e9df',
    sms_text: '\e9e0',
    reimburse: '\e9e1',
    thermometer: '\e9e2',
    tools: '\e9e3',
    carb_counting: '\e9e4',
    scale: '\e9e5',
    consent: '\e9e6',
    consent_no: '\e9e7',
    arrow_wtail_up: '\e9e8',
    arrow_wtail_down: '\e9e9',
    arrow_wtail_left: '\e9ea',
    arrow_wtail_right: '\e9eb',
    dot_net: '\e9ec',
    agile: '\e9ed',
    announcement: '\e9ee',
    archive: '\e9ef',
    arrows_hollow_left_right: '\e9f0',
    automate: '\e9f1',
    big_data: '\e9f2',
    certificate: '\e9f3',
    clean_up: '\e9f4',
    click: '\e9f5',
    deploy: '\e9f6',
    design: '\e9f7',
    design_tools: '\e9f8',
    emerging_technology: '\e9f9',
    gears: '\e9fa',
    idea: '\e9fb',
    java: '\e9fc',
    mainframe: '\e9fd',
    network: '\e9fe',
    paas: '\e9ff',
    performance: '\ea00',
    plug_in: '\ea01',
    responsive: '\ea02',
    sandbox: '\ea03',
    security: '\ea04',
    select: '\ea05',
    structure: '\ea06',
    thumbs_up: '\ea07',
    touch: '\ea08',
    tutorial: '\ea09',
    upload_cloud: '\ea0a',
    data_platform_integration: '\ea0b',
    dermatology: '\ea0c',
    e_health: '\ea0d',
    health_data_exchange: '\ea0e',
    hospital_information_system: '\ea0f',
    integrate: '\ea10',
    ophthalmology: '\ea11',
    pathology: '\ea12',
    checkmark_bold: '\ea13',
    menu_hidden: '\ea14',
    brain: '\ea15',
    code: '\ea16',
    code_folder: '\ea17',
    firewall: '\ea18',
    play_media: '\ea19',
    router: '\ea1a',
    server: '\ea1b',
    web_search: '\ea1c',
    wrench: '\ea1d',
    activity: '\ea1e',
    bill: '\ea1f',
    checklist: '\ea20',
    demonstration: '\ea21',
    micro_learning: '\ea22',
    open_source: '\ea23',
    sent: '\ea24',
)