// 9/30/2020 All this yanked from oc-carbon-provider-portal/src/styles/_variables.scss
// It is a giant mess and needs to be cleaned up. -sam

$oc-white: #ffffff;
$oc-gray-1: #282A2E;
$oc-gray-2: #434448;
$oc-gray-3: #63666A;
$oc-gray-3_5: #717577;
$oc-gray-4: #888B8D;
$oc-gray-5: #B1B3B3;
$oc-gray-6: #D0D0CE;
$oc-gray-7: #EFEFEE;
$oc-gray-7-50: rgba(#EFEFEE, 0.5);
$oc-gray-10: #f6f6f6;
$oc-red: #C40000;
$oc-red-dark: #9e0101;
$oc-violet: #8061BC;
$oc-green: #007000;
$oc-green-2: #92BE48;
$oc-green-3: #6DD400;

$oc-sky-blue: #D9F6FA; 
$oc-dark-blue: #002677; 

$oc-orange-2: #FF612B;
$oc-gold: #F7B72D;

$accessibleOrange3:#FF612B;
$cahpsQuote: #005E9D;
$cahpsQuoteHighRisk: #C40000;

////////////////////////////////////////
//  These colors came from UI Toolkit
$grey1:#282A2E;
$grey2:#434448;
$grey3:#63666A;
$grey4:#888B8D;
$grey5:#B1B2B3;
$grey6:#D0D0CE;
$grey7:#EFEFEE;
$grey8: #ECF2F9;
$white:#ffffff;
$accessibleOrange3:#FF612B;
$optumBrandOrange:#FF612B;
$optumBrandGold:#F2B411;
$goState:#627D32;
$errorState:#C40000;
$errorStateBackground: #FCE9E7;
$textLinkBlue:#196ECF;
$selectState:#DAE4F3;

/* SECONDARY */
$orange1: #492003;
$orange2:#793605;
$orange4: #EA8636;
$orange5:#F1B273;
$orange6:#F6CE9E;
$orange7: #FDF8DF;

$yellow1:#5A3605;
$yellow2:#855A09;
$yellow3: #C6900E;
$yellow4: #F3BC29;
$yellow5: #F6D270;
$yellow6: #F8E1A0;
$yellow7: #FBF8E7;

$green1: #2A3615;
$green2: #465924;
$green3: #6E8D3A;
$accentGreen2:#90BC53;
$green5: #B5D28C;
$green6: #CEE1B3;
$green7: #F3F8EC;
$green8: #008000;

$teal1: #033933;
$teal2: #055F54;
$accentTeal1: #078576;
$teal3: #1C9182;
$teal4: #5AB5A5;
$accentTeal2: #6FC1B1;
$teal5: #93D1C5;
$teal6: #B7E0D8;
$teal7: #EDF7F5;

$blue1: #002843;
$blue2: #004370;
$blue3: #1965AE;
$blue4: #5686CA;
$blue5: #8FAEDC;
$blue6: #B4C9E7;
$blue7: #ECF2F9;

$purple1: #1C133A;
$purple2: #2F1F61;
$accentPurple1:#422C88;
$purple3: #523995;
$accentPurple2: #8061BC;
$purple5: #AA96D2;
$purple6: #C7B9E1;
$purple7: #F1EDF8;

$red1: #3D1011;
$red2: #661A1D;
$accentRed1:#C40000;
$accentRed2: #D13F44;
$red5: #E07F82;
$red6: #EBAAAC;
$red7: #FAEAEA;

////////////////////////////////////////

// Default - this can be replaced by AEM color palette
// Primary OC  P #434448 h1 #616161 h2 #ffffff
// Primary SMA P #4A148B h1 #BFB0D7 h2 #DDD6EA
$oc-primary: #434448;
$oc-primary-hue1: #616161;
$oc-primary-hue2: #ffffff;
$oc-referral-background: #f3f7fa;

$oc-primary-text: white;
$oc-primary-h1-text: white;
$oc-primary-h2-text: #333;

// Accent OC  #F7B72D
$oc-accent: #F7B72D;
$oc-accent-hue1: #c25608;
$oc-accent-hue2: #E7A694;

$oc-accent-text: white;
$oc-accent-h1-text: white;
$oc-accent-h2-text: #333;

// Warn
$oc-warn: #C40000;
$oc-warn-hue1: #982A37;
$oc-warn-hue2: #C73747;

$oc-warn-text: white;
$oc-warn-h1-text: white;
$oc-warn-h2-text: #333;

// Links
$oc-link: #196ECF;
$oc-link-hue1: #196ECF;
$oc-link-hue2: #C73747;

$oc-link-text: #196ECF;
$oc-link-h1-text: #00549F;
$oc-link-h2-text: #333;

// This is Tim's code. Please refactor this or put it in the right place
$oc-border: #d0d0ce;
$oc-required: #C40000;
$oc-alternate: #01579b;
$oc-optum-green: #617E37;

//jens when started project - will remove
$panelBlue:#80bfff;
$DarkGray:#333;
$LightGray:#999;




// 9/30/2020 Added to complete colors for icons. -sam

$oc-icon-grey: #848484;

$roster-blue-fill-icon:#145d9d;
$roster-gray-fill-icon:#85807b;

$oc-orange-3: #ce4b27;
$oc-orange-4: #c05928;
$oc-arrow-blue: #035e9c;
$oc-info-filled: #f5b52a;

// 03/22/2022 Adding in action-footer colors

$action-footer-background: rgb(248, 248, 249);
$action-footer-border: rgb(237, 238, 239);

// Optum Color map
$optum-colors: (
    oc-white: $oc-white,
    oc-gray-1: $oc-gray-1,
    oc-gray-2: $oc-gray-2,
    oc-gray-3: $oc-gray-3,
    oc-gray-3_5: $oc-gray-3_5,
    oc-gray-4: $oc-gray-4,
    oc-gray-5: $oc-gray-5,
    oc-gray-6: $oc-gray-6,
    oc-gray-7: $oc-gray-7,
    oc-gray-7-50: $oc-gray-7-50,
    oc-gray-10: $oc-gray-10,
    oc-red: $oc-red,
    oc-red-dark: $oc-red-dark,
    oc-violet: $oc-violet,
    oc-gold: $oc-gold,
    oc-green: $oc-green,
    oc-green-2: $oc-green-2,
    oc-green-3: $oc-green-3,
    oc-sky-blue: $oc-sky-blue,
    oc-dark-blue: $oc-dark-blue,
    oc-orange-2: $oc-orange-2,
    accessibleOrange3: $accessibleOrange3,
    cahpsQuote: $cahpsQuote,
    cahpsQuoteHighRisk: $cahpsQuoteHighRisk,
    grey1: $grey1,
    grey2: $grey2,
    grey3: $grey3,
    grey4: $grey4,
    grey5: $grey5,
    grey6: $grey6,
    grey7: $grey7,
    grey8: $grey8,
    optumBrandOrange: $optumBrandOrange,
    optumBrandGold: $optumBrandGold,
    goState: $goState,
    errorState: $errorState,
    errorStateBackground: $errorStateBackground,
    textLinkBlue: $textLinkBlue,
    selectState: $selectState,
    orange1: $orange1,
    orange2: $orange2,
    orange4: $orange4,
    orange5: $orange5,
    orange6: $orange6,
    orange7: $orange7,
    yellow1: $yellow1,
    yellow2: $yellow2,
    yellow3: $yellow3,
    yellow4: $yellow4,
    yellow5: $yellow5,
    yellow6: $yellow6,
    yellow7: $yellow7,
    green1: $green1,
    green2: $green2,
    green3: $green3,
    accentGreen2: $accentGreen2,
    green5: $green5,
    green6: $green6,
    green7: $green7,
    green8: $green8,
    teal1: $teal1,
    teal2: $teal2,
    accentTeal1: $accentTeal1,
    teal3: $teal3,
    teal4: $teal4,
    accentTeal2: $accentTeal2,
    teal5: $teal5,
    teal6: $teal6,
    teal7: $teal7,
    blue1: $blue1,
    blue2: $blue2,
    blue3: $blue3,
    blue4: $blue4,
    blue5: $blue5,
    blue6: $blue6,
    blue7: $blue7,
    purple1: $purple1,
    purple2: $purple2,
    accentPurple1: $accentPurple1,
    purple3: $purple3,
    accentPurple2: $accentPurple2,
    purple5: $purple5,
    purple6: $purple6,
    purple7: $purple7,
    red1: $red1,
    red2: $red2,
    accentRed1: $accentRed1,
    accentRed2: $accentRed2,
    red5: $red5,
    red6: $red6,
    red7: $red7,
    oc-primary: $oc-primary,
    oc-primary-hue1: $oc-primary-hue1,
    oc-primary-hue2: $oc-primary-hue2,
    oc-referral-background: $oc-referral-background,
    oc-accent: $oc-accent,
    oc-accent-hue1: $oc-accent-hue1,
    oc-accent-hue2: $oc-accent-hue2,
    oc-warn: $oc-warn,
    oc-warn-hue1: $oc-warn-hue1,
    oc-warn-hue2: $oc-warn-hue2,
    oc-warn-text: $oc-warn-text,
    oc-warn-h1-text: $oc-warn-h1-text,
    oc-warn-h2-text: $oc-warn-h2-text,
    oc-link: $oc-link,
    oc-link-hue1: $oc-link-hue1,
    oc-link-hue2: $oc-link-hue2,
    oc-link-text: $oc-link-text,
    oc-link-h1-text: $oc-link-h1-text,
    oc-link-h2-text: $oc-link-h2-text,
    oc-border: $oc-border,
    oc-required: $oc-required,
    oc-alternate: $oc-alternate,
    oc-optum-green: $oc-optum-green,
    panelBlue: $panelBlue,
    oc-icon-grey: $oc-icon-grey,
    oc-orange-3: $oc-orange-3,
    oc-orange-4: $oc-orange-4,
    oc-arrow-blue: $oc-arrow-blue,
    oc-info-filled: $oc-info-filled
);