.img-title-text {
    font-size: 22px;
    font-weight: 300;
    // line-height: 13px;
    // top: 14px;
    // position: relative;
}

.vertical-bar {
    margin-left: 10px;
    margin-right: 10px;
    color: #d0d0ce;
    font-size: 22px;
    // line-height: 19px;
    // top: 14px;
    // position: relative;
}
