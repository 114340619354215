// OPTUM SANS - Brand 2023
@font-face {
  font-family: 'EnterpriseSans-RegularItalic';
  src: url('/assets/fonts/EnterpriseSans/EnterpriseSans-RegularItalic.eot');
  src: url('/assets/fonts/EnterpriseSans/EnterpriseSans-RegularItalic.eot?#ie-fix') format('embedded-opentype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-RegularItalic.otf') format('opentype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-RegularItalic.ttf') format('truetype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-RegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'EnterpriseSans-Regular';
  src: url('/assets/fonts/EnterpriseSans/EnterpriseSans-Regular.eot');
  src: url('/assets/fonts/EnterpriseSans/EnterpriseSans-Regular.eot?#ie-fix') format('embedded-opentype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-Regular.otf') format('opentype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-Regular.ttf') format('truetype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'EnterpriseSans-Bold';
  src: url('/assets/fonts/EnterpriseSans/EnterpriseSans-Bold.eot');
  src: url('/assets/fonts/EnterpriseSans/EnterpriseSans-Bold.eot?#ie-fix') format('embedded-opentype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-Bold.otf') format('opentype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-Bold.ttf') format('truetype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'EnterpriseSans-BoldItalic';
  src: url('/assets/fonts/EnterpriseSans/EnterpriseSans-BoldItalic.eot');
  src: url('/assets/fonts/EnterpriseSans/EnterpriseSans-BoldItalic.eot?#ie-fix') format('embedded-opentype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-BoldItalic.otf') format('opentype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-BoldItalic.ttf') format('truetype'),
    url('/assets/fonts/EnterpriseSans/EnterpriseSans-BoldItalic.woff2') format('woff2');
}


/* $OPTUM MICRO INTERACTION 16PX */
@font-face {
    font-family: 'Optum-Micro-interaction-16';
    src:  url('/assets/fonts/Optum-Micro-interaction-16px_Font/Optum-Micro-interaction-16.eot?662xlp');
    src:  url('/assets/fonts/Optum-Micro-interaction-16px_Font/Optum-Micro-interaction-16.eot?662xlp#iefix') format('embedded-opentype'),
    url('/assets/fonts/Optum-Micro-interaction-16px_Font/Optum-Micro-interaction-16.woff2?662xlp') format('woff2'),
    url('/assets/fonts/Optum-Micro-interaction-16px_Font/Optum-Micro-interaction-16.ttf?662xlp') format('truetype'),
    url('/assets/fonts/Optum-Micro-interaction-16px_Font/Optum-Micro-interaction-16.woff?662xlp') format('woff'),
    url('/assets/fonts/Optum-Micro-interaction-16px_Font/Optum-Micro-interaction-16.svg?662xlp#Optum-Micro-interaction-16') format('svg');
    font-weight: normal;
    font-style: normal;
}
