.card-icon-sm {
    overflow: hidden;

    .card-icon-aside {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        padding: 1rem;

        i,
        svg,
        .feather {
            height: 3rem;
            width: 3rem;
        }
    }

    .card-body {
        padding: 1rem;
    }
}
