.card-portfolio {
    position: relative;
    overflow: hidden;
    border: none;

    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3.5rem;
        width: 100%;
        text-align: center;

        .card-title {
            font-size: 0.75rem;
            margin: 0;
            text-transform: uppercase;
            font-weight: 800;
            letter-spacing: 0.1em;
            color: $gray-800;
        }
    }

    @include media-breakpoint-up(md) {
        .card-body {
            position: absolute;
            border-radius: $border-radius;
            opacity: 0;
            height: 100%;
            transition: opacity 0.25s ease-in-out;
            background-color: fade-out($primary, 0.1);

            .card-title {
                color: $white;
            }
        }

        &:hover {
            .card-body {
                opacity: 1;
            }
        }
    }
}