.widget-list {
    ul {
        padding:0;
        li.widget-list-item {
            list-style: none;
            padding: 0px;
            .color-box {
                width: 15px;
                height: 15px;
                margin: 5px auto 0px;
                border-radius: 100%;
            }
        }
    }
}
