// Text utilities
.text-xs {
    font-size: $font-size-xs !important;
}

.text-sm {
    font-size: $font-size-sm !important;
}

.text-md {
    font-size: $font-size-medium !important;
}

.text-lg {
    font-size: $font-size-large !important;
}

.text-xl {
    font-size: $font-size-xl !important;
}
.text-xxl {
    font-size: $font-size-xxl !important;
}

.f-bold {
    font-family: 'EnterpriseSans-Bold', -apple-system, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.default-algorithm-table * * {
    font-family: 'EnterpriseSans-Regular' !important;
}

.danger-text {
    color: $danger;
}

/* generated text color classes to use, just assert color name */
@each $color, $value in $optum-colors {
    .text-#{$color} {
        color: $value !important;
    }
}

.econsult-text {
    border: 2px solid $oc-red-dark;
    font-weight: bold;
    font-size: var(--bs-body-font-size);
    color: var(--bs-body-color);
    border-radius: 15px;
    background-color: $oc-gray-10;

    &.econsult-radio {
        padding-left: 2rem;
    }
}
