@import './optum/variables/colors';

// 
// Color overrides
// 

// This file is intentionally blank
// Use this file to override any color variables within the project

// You can also replace this file with a file exported from the customizer, 
// which you can access from the widget at https://themes.startbootstrap.com/sb-admin-pro/

$primary: #002677;
$secondary: #FF612B;
$success: #007000;
$info: #F5B700;
$warning: #c25608;
$danger: #C40000;


$body-bg: white;
$body-color: $oc-gray-1;
$box-color: $gray-400;
