// * * * * * * * * * * * * *
// * * Simple DataTables * * 
// * * * * * * * * * * * * *

// 
// Simple DataTables is a third party plugin included with this
// theme to create functional data tables
// 
// Plugin docs: https://github.com/fiduswriter/Simple-DataTables
// 

// Override the Simple DataTables Styling

// Sets default responsiveness of the dataTable

.dataTable-wrapper .dataTable-container {
    @extend .table-responsive;
    font-size: $font-size-sm;
}

.dataTable-wrapper.no-header .dataTable-container {
    border-top: none;
}

.dataTable-wrapper.no-footer .dataTable-container {
    border-bottom: none;
}

.dataTable-top {
    padding: 0 0 1rem;
}

.dataTable-bottom {
    padding: 0;
}

.dataTable-top>nav:first-child,
.dataTable-top>div:first-child,
.dataTable-bottom>nav:first-child,
.dataTable-bottom>div:first-child {
    float: left;
}

.dataTable-top>nav:last-child,
.dataTable-top>div:last-child,
.dataTable-bottom>nav:last-child,
.dataTable-bottom>div:last-child {
    float: right;
}

.dataTable-selector {
    @extend .form-select;
    width: auto;
    display: inline-block;
    padding-left: 1.125rem;
    padding-right: 2.125rem;
    margin-right: 0.25rem;
}

.dataTable-input {
    @extend .form-control;
}

.dataTable-info {
    margin: 7px 0;
}

/* PAGER */

.dataTable-pagination ul {
    @extend .pagination;
}

.dataTable-pagination li {
    @extend .page-item;
}

.dataTable-pagination a {
    @extend .page-link;
}

.dataTable-pagination a:hover {
    background-color: $pagination-hover-bg;
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
    background-color: $pagination-active-bg;
}

.dataTable-pagination .ellipsis a,
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
    cursor: not-allowed;
}

.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
    cursor: not-allowed;
    opacity: 0.4;
}

.dataTable-pagination .pager a {
    font-weight: bold;
}

/* TABLE */

.dataTable-table {
    @extend .table;
    @extend .table-bordered;
    @extend .table-hover;
    border-collapse: collapse;
}

.dataTable-table>tbody>tr>td,
.dataTable-table>tbody>tr>th,
.dataTable-table>tfoot>tr>td,
.dataTable-table>tfoot>tr>th,
.dataTable-table>thead>tr>td,
.dataTable-table>thead>tr>th {
    vertical-align: top;
    padding: $table-cell-padding-y $table-cell-padding-x;
}

.dataTable-table>thead>tr>th {
    vertical-align: bottom;
    text-align: left;
    border-bottom: none;
}

.dataTable-table>tfoot>tr>th {
    vertical-align: bottom;
    text-align: left;
}

.dataTable-table th {
    vertical-align: bottom;
    text-align: left;
}

.dataTable-table th a {
    text-decoration: none;
    color: inherit;
}

.dataTable-sorter {
    display: inline-block;
    height: 100%;
    position: relative;
    width: 100%;
    padding-right: 1rem;
}

.dataTable-sorter::before,
.dataTable-sorter::after {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    right: 4px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    opacity: 0.2;
}

.dataTable-sorter::before {
    bottom: 4px;
}

.dataTable-sorter::after {
    top: 0px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
    opacity: 0.6;
}

.dataTables-empty {
    text-align: center;
}

.dataTable-top::after,
.dataTable-bottom::after {
    clear: both;
    content: " ";
    display: table;
}

// DataTable button

.btn-datatable {
    height: 20px !important;
    width: 20px !important;
    font-size: 0.75rem;
    border-radius: $border-radius !important;
}
