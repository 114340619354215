// Global resets

html,
body {
    height: 100%;
}

body {
    overflow-x: hidden;
}
