.bg-oc-gray-1 {
    color: white !important;
}

.bg-oc-gray-2 {
    color: white !important;
}

/* generated background color classes to use, just assert color name */
@each $color, $value in $optum-colors {
    .bg-#{$color} {
        background-color: $value !important;
    }
}
