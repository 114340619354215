// 
// Project styles
// 

// Use this file to override SCSS within the project, or to add new SCSS
// for creating your own CSS components

// Override navbar icon styling

.topnav {
    .navbar-brand {
        width: auto;
        img {
            height: 2.3rem;
        }
    }
}


// Fix for account/admin navigation dropdown
.topnav.navbar .dropdown-menu-end {
    top: 0 !important;
}

// Custom DataTable Styling

.table-sb-stack {

    &.scrolling-table {
        max-height: 58vh;
        min-height: 50vh;
        border-radius: 0.35em;
    }

    .table {
        th {
            border: none;
        }

        td,
        td {
            vertical-align: middle;
        }

        .form-check {
            min-height: 1.25rem;
        }
    }

    .pagination {
        margin-bottom: 0;
    }

}


.sidenav {
    font-size: 1rem;
}

.footer-dark {
    background-color: $grey1;
}

.sidenav-menu {
    overflow-x: hidden;
}
.nav-link-text, .sidenav-footer-title {
    white-space: nowrap;
}
// Responsive styling for the sidenav layout
@include media-breakpoint-up(lg) {
    #layoutSidenav {
        #layoutSidenav_nav {
            transform: none; // override default
            transition: width 0.15s ease-in-out;
            .icon-chevron_left {
                display: block;
            }
            .icon-chevron_right {
                display: none;
            }
        }

        #layoutSidenav_content {
            margin-left: 0;
        }
    }

    // Behavior for the sidenav collapse on screens larger than the med breakpoint
    .sidenav-toggled {
        #layoutSidenav {
            #layoutSidenav_nav {
                transform: none;
                width: 48px;
                .nav-link-text, .sidenav-footer-title {
                    display: none;
                }
                .icon-chevron_left {
                    display: none;
                }
                .icon-chevron_right {
                    display: block;
                }
            }

            #layoutSidenav_content {
                margin-left: calc((#{$sidenav-base-width} - 48px) * -1);
                // Removes the sidenav overlay on screens larger than the med breakpoint
                &:before {
                    display: none;
                }
            }
        }
    }
}

.optum-icon-for-loader {
    width: 300px;
}

.loader-icon {
    width: 25px;
    height: 25px;
}

sbf-side-nav-developer {
    .sidenav-light {
        .sidenav-menu {
            .nav-link {
                &.active {
                    color: $primary;
                    .nav-link-icon {
                        color: $primary;
                    }
                }
            }
        }
    }
}

.pdf-modal {
    .modal-dialog {
        max-width: 90vw !important;
    }
    .modal-body {
        height: 90vh !important;
    }
}

.ngb-highlight {
    color: $secondary
}

.form-check-input:checked[type=radio] {
    background-image: none !important;
}

.static-action-footer-padding {
    padding-bottom: $static-action-footer-padding;
}

.static-action-footer {
    padding-bottom: $static-action-footer;
}

.eligibility-green {
    color: $oc-green-3
}