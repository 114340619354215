@mixin iconMixin {
    @each $glyphName, $unicode in $fontIconsMap {
        &-#{$glyphName}:before {
            font-family: 'Optum-Micro-interaction-16';
            font-size: $font-size-normal;    
            content: $unicode;
        }
    }
}

.icon {
    @include iconMixin;
}

.icon-xxxl:before {
    font-size: $font-size-xxxl !important;
}

.icon-xxl:before {
    font-size: $font-size-xxl !important;
}

.icon-xl:before {
    font-size: $font-size-xl !important;
}



.icon-lg:before {
    font-size: $font-size-large !important;
}

.icon-md:before {
    font-size: $font-size-medium !important;
}

.icon-sm:before {
    font-size: $font-size-sm !important;
}

.icon-xs:before {
    font-size: $font-size-xs !important;
}

.icon-xxs::before {
    font-size: $font-size-xxs !important;
}

.icon-orange:before {
    color: $oc-orange-4;
}

.icon-green:before {
    color: $oc-green-3;
}

.icon-oc-green:before {
    color: $oc-green;
}

.icon-yellow:before {
    color: $yellow;
}

.icon-primary:before {
    color: $primary;
}

.icon-secondary:before {
    color: $secondary;
}

.icon-gray:before {
    color: $gray-500;
}

.icon-white:before {
    color: white;
}
