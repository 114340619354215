// Default styling for the page header
.page-header-ui {
    position: relative;
    padding-top: $page-header-padding-y;
    padding-bottom: $page-header-padding-y;
    .page-header-ui-content {
        .page-header-ui-title {
            font-size: 2.5rem;
        }
        .page-header-ui-text {
            font-size: 1.15rem;
            &.small {
                font-size: 0.9rem;
            }
        }
    }
}

// Page header style when using a dark background color
.page-header-ui-dark {
    color: $white;
    // Fallback background color
    background-color: $gray-900;
    .page-header-ui-title {
        color: $white;
    }
    .page-header-ui-text {
        @extend .text-white-50;
        a {
            @extend .text-white-75;
        }
    }
    .btn-link {
        @extend .text-light;
    }
}

// Page header style when using a light background
.page-header-ui-light {
    // Fallback background color
    background-color: $gray-100;
    .page-header-ui-text {
        color: $gray-600;
        a {
            color: $gray-700;
        }
    }
    .btn-link {
        @extend .text-dark;
    }
}

// Behavior for fixed navbar
.page-header-ui.navbar-fixed {
    .page-header-ui-content {
        padding-top: $page-header-padding-y + $navbar-marketing-padding-y * 2 + 1rem;
    }
    .navbar {
        @extend .fixed-top;
    }
}