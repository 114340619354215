.nav-wizard {
    .nav-link {
        display: flex;
        align-items: center;
        padding: 2rem;

        .wizard-step-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 2.5rem;
            width: 2.5rem;
            flex-shrink: 0;
            margin-right: 1rem;
            border-radius: $border-radius;
            background-color: $primary;
            color: white;

            i,
            svg,
            .feather {
                color: white;
            }
        }

        .wizard-step-text {
            line-height: 1;
            text-align: left;

            .wizard-step-text-name {
                font-size: 1rem;
                margin-bottom: 0.25rem;
                color: $primary;
            }

            .wizard-step-text-details {
                font-weight: 400;
                font-size: $font-size-sm;
                color: $body-color;
            }
        }

        &.active {
            .wizard-step-icon {
                background-color: $white;
                color: $primary;

                i,
                svg,
                .feather {
                    color: $primary;
                }
            }

            .wizard-step-text {
                .wizard-step-text-name {
                    color: $white;
                }

                .wizard-step-text-details {
                    color: fade-out($white, 0.5);
                }
            }
        }
    }
}
