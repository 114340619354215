.list-group-careers {
    margin-bottom: 3rem;

    .list-group-item {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
